import { Controller } from "stimulus";

export default class extends Controller {
  static targets = []

  connect() {
    if (this.element.classList.contains('modal-close')) { this.element.classList.remove('modal-close') }
    this.element.classList.add('modal-open')
  }

  closeModal() {
    if (this.element.classList.contains('modal-open')) { this.element.classList.remove('modal-open') }
    this.element.classList.add('modal-close')

    setTimeout(() => {
      // Removes the modal
      this.element.remove();
    }, 300);
  }
}
