// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";

Rails.start();
Turbolinks.start();
ActiveStorage.start();

// External imports
import "bootstrap";
import "@hotwired/turbo-rails";

import { Turbo } from "@hotwired/turbo-rails";
Turbo.session.drive = false;

import { initHotjar } from '../plugins/init_hotjar'
import { initSortable } from '../plugins/sortable'

// Internal imports
import "controllers";

document.addEventListener('turbolinks:load', () => {
  initHotjar();
  initSortable();
});

// Flatpickr
import { initFlatpickr } from "../plugins/flatpickr";

initFlatpickr();
