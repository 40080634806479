import { connectServiceWorker } from '../../assets/javascripts/serviceworker-companion';
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['addButton', 'message', 'timer']

  connect() {
    connectServiceWorker();
    this.countDownTime();

    window.addEventListener("beforeinstallprompt", (event) => {
      // Prevent the mini-infobar from appearing on mobile
      event.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = event;
      // Update UI
      this.messageTarget.classList.add('d-none');
      this.addButtonTarget.classList.remove('d-none');
      this.addButtonTarget.innerText = "Instalar";
    });

    window.addEventListener('appinstalled', () => {
      // Update UI
      this.messageTarget.classList.add('d-none');
      this.addButtonTarget.classList.remove('d-none')
      this.addButtonTarget.innerText = 'Instalado!';
    });
  }

  addToHome(event) {
    event.preventDefault();

    // Show the install prompt
    this.deferredPrompt.prompt();
  }

  countDownTime() {
    let timeLeft = 30
    const timerId = setInterval(() => {
      if (timeLeft < 0) {
        clearInterval(timerId)
        this.timerTarget.innerText = "Não conseguimos verificar a compatibilidade. Por favor, tente atualizar a página ou testar no navegador Chrome.";
      } else {
        this.timerTarget.innerText = `${timeLeft} segundos`;
        timeLeft -= 1;
      }
    }, 1000);
  }
}
