import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "link" ]

  geolocate() {
    if (!navigator.geolocation) {
      this.linkTarget.textContent = "Geolocalização não suportada neste browser."
    } else {
      navigator.geolocation.getCurrentPosition(this.success.bind(this), this.error.bind(this));
    }
  }

  success(position) {
    const geolocation = {
      'latitude': position.coords.latitude,
      'longitude': position.coords.longitude
    }

    this.linkTarget.textContent = `Procurando eventos na sua cidade...`

    this.search(geolocation);
  }

  error() {
    this.linkTarget.textContent = "Unable to get your location."
  }

  search(geolocation) {
    const token = document.getElementsByName("csrf-token")[0].content

    // Fetching data
    fetch('/events/user_geolocation', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "X-CSRF-Token": token
      },
      body: JSON.stringify(geolocation)
    })
    .then((data) => {
      // console.log(data);
      window.location.href = '/events'
    })
  }
}
