import Sortable from 'sortablejs';

const token = document.getElementsByName("csrf-token")[0].content;

const saveCategoriesOrder = (event) => {
  const newList = document.querySelectorAll('.category-card');
  newList.forEach((item, index) => {
    const category_id = item.dataset.categoryId;
    const newOrder = index + 1
    const url = `categories/${category_id}/set_new_order`;

    fetch(url, {
      method: 'PATCH',
      headers: { "Content-Type": "application/json", "X-CSRF-Token": token },
      body: JSON.stringify({ "display_order": newOrder })
    })
  })
}

const sortCategories = () => {
  const list = document.getElementById('categories-list');
  if (list) {
    Sortable.create(list, {
      ghostClass: "ghost",
      animation: 150,
      group: 'categories-group',
      onUpdate: saveCategoriesOrder
    });
  }
};

const initSortable = () => {
  sortCategories();
}

export { initSortable };
