import { Controller } from "@hotwired/stimulus";
import Typed from "typed.js";

export default class extends Controller {
  connect() {
    new Typed(this.element, {
      strings: ["no seu estilo",
                "no seu ritmo preferido",
                "com os amigos",
                "com a família",
                "com o amor",
                "na sua região",
                "no seu bairro",
                "na sua cidade",
                "hoje",
                "essa semana",
                "esse mês"],
      typeSpeed: 70,
      loop: true,
      showCursor: false
    });
  }
}
