import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["btn"]

  connect() {
    const btns = this.btnTargets
    let event = new Event('click')
    let stopSlide = 0;
    const timer = ms => new Promise(res => setTimeout(res, ms))

    async function slide () {
      while (stopSlide < 50) {
        for (let i = 0; i < btns.length; i++) {
          btns[i].dispatchEvent(event)
          await timer(3000);
        }
        stopSlide += 1
      }
    }

    slide();
  }
}
