import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [];

  autoFireForm() {
    console.log("form");
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      Rails.fire(this.element, 'submit')
    }, 500)
  }

  handleFocus() {
    this.element.querySelector('#focus').classList.add('form-box-shadow');
  }

  handleFocus2() {
    this.element.querySelector('#focus2').classList.add('form-box-shadow');
  }

  handleFocus3() {
    this.element.querySelector('#focus3').classList.add('form-box-shadow');
  }

  handleFocus3() {
    this.element.querySelector('#focus3').classList.add('form-box-shadow');
  }

  handleBlur() {
    this.element.querySelector('#focus').classList.remove('form-box-shadow');
  }

  handleBlur2() {
    this.element.querySelector('#focus2').classList.remove('form-box-shadow');
  }

  handleBlur3() {
    this.element.querySelector('#focus3').classList.remove('form-box-shadow');
  }
}
