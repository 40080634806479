import AriaController from "./aria_controller";

export default class extends AriaController {
  static targets = ["dashNav", "btnToggle" ]

  minimizeMenu = () => {
    this.dashNavTarget.classList.toggle('minimized');
    this.ariaNav(this.dashNavTarget, this.btnToggleTargets);
  }
}
