import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
	static targets = ['result', 'title'];

	shareData() {
		const shareData = {

			title: this.titleTarget.value,
			url: encodeURIComponent(this.data.get('urlValue')),
		};

		return shareData;
	}

	// Share for navigator
	async share(e) {
		e.preventDefault();

    if (this.element.classList.contains('opacity')) {
      this.element.classList.remove('opacity')

      setTimeout(() => {
        this.element.classList.add('opacity')
      }, 300);
    }

		try {
			await navigator.share({
        title: this.titleTarget.value,
        url: encodeURIComponent(this.data.get('eventId')),
      });
			this.resultTarget.textContent;
		} catch (err) {
			console.log(err);
		}
	}

	// Method to Open URLs
	openUrl(url) {
		window.open(url, '_blank');
	}
}
