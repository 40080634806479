const connectServiceWorker = () => {
  if (navigator.serviceWorker) {
    navigator.serviceWorker.register('/serviceworker.js', { scope: './' })
      .then(function (reg) {
        // console.log('[Companion]', 'Service worker registered!');
      });
  }
}

export { connectServiceWorker }
